<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm mới'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :hideSearch="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
    />

    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th><b-form-checkbox @change="selectAllCheckbox($event)" /></b-th>
          <b-th
            colspan="1"
          >
            TÊN CHỈ TIÊU THỐNG KÊ
          </b-th>
          <b-th
            colspan="1"
          >
            MÃ SỐ
          </b-th>
          <b-th
            class="border-color"
            colspan="11"
          >
            PHÂN LOẠI TNLĐ THEO MỨC ĐỘ THƯƠNG TẬT
          </b-th>
          <b-th
            class="border-color"
            colspan="6"
          >
            THIỆT HẠI DO TNLĐ
          </b-th>
          <b-th
            colspan="1"
          >
            CHỨC NĂNG
          </b-th>
        </b-tr>
      </b-thead>
      <b-thead>
        <b-tr>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            class="border-color"
            colspan="3"
          >
            SỐ VỤ (VỤ)
          </b-th>
          <b-th
            class="border-color"
            colspan="8"
          >
            SỐ NGƯỜI BỊ NẠN (NGƯỜI)
          </b-th>
          <b-th
            colspan="1"
          >
            TỔNG SỐ NGÀY NGHỈ VÌ TAI NẠN LAO ĐỘNG
          </b-th>
          <b-th
            class="border-color"
            colspan="4"
          >
            CHI PHÍ TÍNH BẰNG TIỀN (1.000Đ)
          </b-th>
          <b-th
            colspan="1"
          >
            THIỆT HẠI TÀI SẢN (1.000Đ)
          </b-th>
          <b-th
            colspan="1"
          ></b-th>
        </b-tr>
      </b-thead>
      <b-thead>
        <b-tr>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            colspan="1"
          >
            SỐ VỤ CÓ NGƯỜI CHẾT
          </b-th>
          <b-th
            colspan="1"
          >
            SỐ VỤ CÓ 2 NGƯỜI BỊ NẠN TRỞ LÊN
          </b-th>
          <b-th
            class="border-color"
            colspan="2"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class="border-color"
            colspan="2"
          >
            SỐ LAO ĐỘNG NỮ
          </b-th>
          <b-th
            class="border-color"
            colspan="2"
          >
            SỐ NGƯỜI CHẾT
          </b-th>
          <b-th
            class="border-color"
            colspan="2"
          >
            SỐ NGƯỜI BỊ THƯƠNG NẶNG
          </b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class="border-color"
            colspan="3"
          >
            KHOẢNG CHI CỤ THỂ CỦA CƠ SỞ
          </b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
        </b-tr>
      </b-thead>
      <b-thead>
        <b-tr>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            Y TẾ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            TRẢ LƯƠNG TRONG THỜI GIAN/ĐIỀU TRỊ
          </b-th>
          <b-th
            class="border-color"
            colspan="1"
          >
            BỒI THƯỜNG/TRỢ CẤP
          </b-th>
          <b-th
            colspan="1"
          ></b-th>
          <b-th
            colspan="1"
          ></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in dataList"
          :key="index"
        >
          <b-td class="pb-25">
            <b-form-checkbox
              :checked="checkId.includes(item.id)"
              @change="selectRowTable($event, item.id)"
            />
          </b-td>
          <b-td
            v-for="key in listAccidentKey"
            :key="key"
            class="pb-1 pt-1"
          >
            {{ item[key] }}
          </b-td>
          <b-td class="pb-1 pt-1">
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(item.id)"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <div v-if="dataList.length === 0">
      <div class="empty-table d-block">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-img
            class="img-empty-table"
            style="width: 400px"
            fluid
            :src="emptyImg"
            alt="empty table"
          />
        </div>
      </div>
    </div>

    <!-- modal xóa-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <ModalCreateElementAccident
      :id="modalCreateElement"
      :title="'Thêm mới'"
      @accept="handleCreateElement"
    />
  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue'

import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    BImg,
    ButtonAllHeader,
    MyPagination,
    BFormCheckbox,
    ConfirmModal,
    ModalCreateElementAccident: () => import('./ModalCreateElementAccident.vue'),

  },

  props: {
    submitReportId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      emptyImg: require('@/assets/images/svg/No-data.gif'),
      deleteIds: [],
      checkId: [],
      showBtnMultiDelete: false,
      confirmModalId: 'confirm-modal',
      modalCreateElement: 'modal-create-element',
      modalContent: '',
      totalRecord: 0,
      dataList: [],
      urlQuery: {
        submitReportId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      listAccidentKey: [
        'name',
        'code',
        'totalCase',
        'totalCaseDeath',
        'totalCase2Injury',
        'totalWorker',
        'totalWorkerNotOwner',
        'totalFemaleWorker',
        'totalFemaleWorkerNotOwner',
        'countDeath',
        'countDeathNotOwner',
        'countSerious',
        'countSeriousNotOwner',
        'absent',
        'totalCost',
        'healthCost',
        'salaryCost',
        'indemnifyCost',
        'assetCost',
      ],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    // Fetch data
    async fetchData(urlQuery) {
      this.urlQuery.submitReportId = this.submitReportId
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SUBMIT_REPORT_ACCIDENT_ELEMENT, {
        params: this.urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(event, val) {
      if (event === true) {
        this.checkId.push(val)
      }
      if (event === false) {
        const a = this.checkId.findIndex(x => x === val)
        this.checkId.splice(a, 1)
      }
      if (this.checkId.length > 0) {
        this.showBtnMultiDelete = true
      } else if (this.checkId.length < 1 && event === false) {
        this.showBtnMultiDelete = false
      }
    },

    // Chọn xóa tất cả
    selectAllCheckbox(event) {
      if (event === true) {
        this.showBtnMultiDelete = true
        this.checkId = this.dataList.map(element => element.id)
      } else if (event === false) {
        this.showBtnMultiDelete = false
        this.checkId = []
      }
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.checkId
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      const model = {
        ids: this.deleteIds,
        submitReportId: this.submitReportId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_SUBMIT_REPORT_ACCIDENT_ELEMENT, model).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Xóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$bvModal.hide(this.modalCreateElement)
        this.fetchData(this.urlQuery)
        this.checkId = []
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalCreate() {
      this.$bvModal.show(this.modalCreateElement)
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async handleCreateElement(val) {
      const model = {
        ...val,
        submitReportId: this.submitReportId,
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT_ACCIDENT_ELEMENT, model).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Tạo thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalCreateElement)
          this.fetchData(this.urlQuery)
          this.checkId = []
        } else {
          this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .table-simple {
    .table {
      thead {
        tr {
          th {
            background-color: rgba(34, 127, 244, 0.12) !important;
          }
        }
      }
    }
    .style-table-thread {
      width: 50px;
    }
    .bold-text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #2E3A4A !important;
    }
    .italic-text {
      font-family: 'Montserrat';
      font-style: italic;
      font-size: 14px;
      color: #2E3A4A !important;
    }
  }
  .border-color {
    border-bottom: 1px solid #CBCDD2 !important;
  }
</style>
